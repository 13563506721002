import { Link } from "gatsby"
import React from "react"
import { graphql } from "gatsby"

import _ from 'lodash'

import Layout from "../components/layout.js"
import SEO from "../components/seo.js"

import "../styles/category.css"

// To convert string into Title Case
function titleCase(str){
	str = str.toLowerCase().split(' ');
	let final = [ ];
	for(let  word of str){
		 final.push(word.charAt(0).toUpperCase()+ word.slice(1));
	}
  return final.join(' ')
}

function Category( props ) {
  const data = props.data.allMarkdownRemark.group
  //All category
  let allCategory = []
  // Iterate and putting all category into `allCategory array`
  // for including into `keywords`
  data.map( eachCategory => allCategory = allCategory.concat(eachCategory.fieldValue) )

	return (
		<Layout>
			<SEO title={"Category"} keywords={ allCategory } />
      <div id="categoryContainer">
        <table>
          <thead><tr><th>Category of Article</th></tr></thead>
          <tbody>             
            { data.map( eachCategory => 
              <tr>
                <td>
                  <Link id="linkCategory"
                    key={`/${_.kebabCase(eachCategory.fieldValue)}/`}
                    to={`/${_.kebabCase(eachCategory.fieldValue)}/`}
                  >
                    {/* <span>&#x261B; </span> */}
                    <span style={{ fontSize: `15px`}} role="img" aria-label="folder">&#x1F4C2; </span>
                    <span style={{ fontWeight: `600`}}>{titleCase(eachCategory.fieldValue)} </span> 
                    <span 
                      style={{ 
                        fontFamily: `source code pro`, 
                        fontWeight: `500`,
                        color: `gray`,
                        }}
                    >
                      {`(${eachCategory.totalCount})`}
                    </span>
                  </Link>
                </td> 
              </tr>
            )}             
          </tbody>
          <tfoot><tr><td></td></tr></tfoot>
        </table>
      </div>
		</Layout>
	)
}

export default Category;

export const categoryQuery = graphql`
  query CategoryQuery{
    allMarkdownRemark(
      limit: 2000) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
      }
    }
  }
`

// Ignore following words from checking by Code Spell Checker
// cSpell:ignore akinul jony frontmatter